import { DailyForecast, SiteImage, SiteImageSummary, StructureTypeContent } from 'src/@types/site';
import { SiteAction, SiteActionType } from './types';
import { PhotoTiePoints } from 'src/servicesV2/site/types';
import { ProcessingData } from 'src/components/QueueMap/types';

const actions = {
  setSite: (site: any): SiteAction => ({
    payload: { site },
    type: SiteActionType.SET_SITE,
  }),
  fetchSite: (siteId: string, assessmentId?: string): SiteAction => ({
    payload: { siteId, assessmentId },
    type: SiteActionType.FETCH_SITE,
  }),
  setFetchSiteSuccess: (isFetchSiteSuccess: boolean): SiteAction => ({
    payload: { isFetchSiteSuccess },
    type: SiteActionType.SET_FETCH_SITE_SUCCESS,
  }),
  fetchAssessmentImages: (assessmentId: string): SiteAction => ({
    payload: { assessmentId },
    type: SiteActionType.FETCH_ASSESSMENT_IMAGES,
  }),
  setPhotosLoading: (isPhotosLoading: boolean): SiteAction => ({
    payload: { isPhotosLoading },
    type: SiteActionType.SET_PHOTOS_LOADING,
  }),
  fetch5DaysWeathers: (lat: number, lng: number): SiteAction => ({
    payload: { lat, lng },
    type: SiteActionType.FETCH_5_DAYS_WEATHERS,
  }),
  setCurrentSiteAssessmentId: (currentSiteAssessmentId: string): SiteAction => ({
    payload: { currentSiteAssessmentId },
    type: SiteActionType.SET_CURRENT_ASSESSMENT_ID,
  }),
  setAssetProfilePhoto: (assetProfilePhoto: SiteImageSummary): SiteAction => ({
    payload: { assetProfilePhoto },
    type: SiteActionType.SET_ASSET_PROFILE_PHOTO,
  }),
  setCurrentSiteAssessmentPhotos: (currentSiteAssessmentPhotos: SiteImage[]): SiteAction => ({
    payload: { currentSiteAssessmentPhotos },
    type: SiteActionType.SET_CURRENT_SITE_ASSESSMENT_PHOTOS,
  }),
  setAssessmentStaging: (data: any) => ({
    payload: { data },
    type: SiteActionType.SET_ASSESSMENT_STAGING,
  }),
  setDailyForecasts: (dailyForecasts: DailyForecast[]): SiteAction => ({
    payload: { dailyForecasts },
    type: SiteActionType.SET_DAILY_FORECASTS,
  }),
  setLoading: (loading: boolean): SiteAction => ({
    payload: { loading },
    type: SiteActionType.SET_LOADING,
  }),
  setClientSiteIdentifier: (clientSiteIdentifier: string): SiteAction => ({
    payload: { clientSiteIdentifier },
    type: SiteActionType.SET_CLIENT_SITE_IDENTIFIER,
  }),
  fetchSiteAssessmentStaging: (assessmentId: string): SiteAction => ({
    payload: { assessmentId },
    type: SiteActionType.FETCH_SITE_ASSESSMENT_STAGING,
  }),

  //structure select options
  setStructureSelectOptions: (structureSelectOptions: StructureTypeContent): SiteAction => ({
    payload: { structureSelectOptions },
    type: SiteActionType.SET_STRUCTURE_SELECT_OPTIONS,
  }),
  setIsStructureSelectOptionsLoading: (isStructureSelectOptionsLoading: boolean): SiteAction => ({
    payload: { isStructureSelectOptionsLoading },
    type: SiteActionType.SET_IS_STRUCTURE_SELECT_OPTIONS_LOADING,
  }),
  fetchStructureSelectOptions: (): SiteAction => ({
    type: SiteActionType.FETCH_STRUCTURE_SELECT_OPTIONS,
  }),

  //add site
  createSite: (site: any): SiteAction => ({
    payload: { site },
    type: SiteActionType.CREATE_SITE,
  }),
  setIsAddSiteSuccess: (isAddSiteSuccess?: boolean): SiteAction => ({
    payload: { isAddSiteSuccess },
    type: SiteActionType.SET_IS_ADD_SITE_SUCCESS,
  }),

  //bulk insert
  bulkInsert: (file: any, owner_id?: string): SiteAction => ({
    payload: { file, owner_id },
    type: SiteActionType.BULK_INSERT,
  }),
  setBulkInsertLoading: (isBulkInsertLoading: boolean): SiteAction => ({
    payload: { isBulkInsertLoading },
    type: SiteActionType.SET_BULK_INSERT_LOADING,
  }),
  setBulkInsertResult: (bulkInsertResult: any): SiteAction => ({
    payload: { bulkInsertResult },
    type: SiteActionType.SET_BULK_INSERT_RESULT,
  }),

  //edit site
  editSite: (slmId: string, site: any): SiteAction => ({
    payload: { slmId, site },
    type: SiteActionType.EDIT_SITE,
  }),
  setError: (error: Error): SiteAction => ({
    payload: { error },
    type: SiteActionType.SET_ERROR,
  }),
  clearState: (): SiteAction => ({
    type: SiteActionType.CLEAR,
  }),
  editPhotoTiePoints: (photoTiePoints: PhotoTiePoints): SiteAction => ({
    payload: { photoTiePoints },
    type: SiteActionType.EDIT_PHOTO_TIE_POINTS,
  }),
  editCurrentSiteAssessmentPhotos: (editingCurrentSiteAssessmentPhotos: SiteImage): SiteAction => ({
    payload: { editingCurrentSiteAssessmentPhotos },
    type: SiteActionType.EDIT_CURRENT_SITE_ASSESSMENT_PHOTOS,
  }),
  deletePhotoTiePoints: (currentPhotoId: string, currentSiteAssessmentId?: string): SiteAction => ({
    payload: { currentPhotoId, currentSiteAssessmentId },
    type: SiteActionType.DELETE_PHOTO_TIE_POINTS,
  }),
  startAzureImport: (url: string): any => ({
    payload: { url },
    type: SiteActionType.START_AZURE_IMPORT,
  }),
  startAzureExport: (url: string): any => ({
    payload: { url },
    type: SiteActionType.START_AZURE_EXPORT,
  }),
  forceCompleteUpload: () => ({
    type: SiteActionType.FORCE_COMPLETE_UPLOAD,
  }),
  submitQueueForProcessing: (
    processingData: ProcessingData,
    assessmentId: string,
    siteId?: string,
  ): SiteAction => ({
    payload: { processingData, assessmentId, siteId },
    type: SiteActionType.SUBMIT_QUEUE_FOR_PROCESSING,
  }),
  setPhotoTab: (photoTab: number): SiteAction => ({
    payload: { photoTab },
    type: SiteActionType.SET_PHOTO_TAB,
  }),
  setJobProgress: (jobProgress: any): SiteAction => ({
    payload: { jobProgress },
    type: SiteActionType.SET_JOB_PROGRESS,
  }),
  setPhotoLimit: (photoLimit: any): SiteAction => ({
    payload: { photoLimit },
    type: SiteActionType.SET_PHOTO_LIMIT,
  }),
  setPhotoOffset: (photoOffset: any): SiteAction => ({
    payload: { photoOffset },
    type: SiteActionType.SET_PHOTO_OFFSET,
  }),
  setQueueForProcessingLoading: (isSubmitQueueForProcessingLoading: boolean): SiteAction => ({
    payload: { isSubmitQueueForProcessingLoading },
    type: SiteActionType.SET_SUBMIT_QUEUE_FOR_PROCESSING_LOADING,
  }),
  setQueueForProcessingSuccess: (isSubmitQueueForProcessingSuccess: boolean): SiteAction => ({
    payload: { isSubmitQueueForProcessingSuccess },
    type: SiteActionType.SET_SUBMIT_QUEUE_FOR_PROCESSING_SUCCESS,
  }),
  deleteSiteAssessmentStaging: (assessmentId: string): SiteAction => ({
    payload: { assessmentId },
    type: SiteActionType.DELETE_SITE_ASSESSMENT_STAGING,
  }),
  deleteSiteUser: (site_user_id: string): SiteAction => ({
    payload: { site_user_id },
    type: SiteActionType.DELETE_SITE_USER,
  }),

  //assessment sales order
  fetchAssessmentSalesOrder: (assessmentId: string): SiteAction => ({
    payload: { assessmentId },
    type: SiteActionType.FETCH_ASSESSMENT_SALES_ORDER,
  }),
  setIsAssessmentSalesOrder: (isAssessmentSalesOrder: boolean): SiteAction => ({
    payload: { isAssessmentSalesOrder },
    type: SiteActionType.SET_IS_ASSESSMENT_SALES_ORDER,
  }),
  setIsAssessmentSalesOrderLoading: (isAssessmentSalesOrderLoading: boolean): SiteAction => ({
    payload: { isAssessmentSalesOrderLoading },
    type: SiteActionType.SET_IS_ASSESSMENT_SALES_ORDER_LOADING,
  }),

};

export default actions;
