import { ApplicationState } from 'src/store/types';
import { SiteImagesFolders } from 'src/@types/site';
import { formatPhotoUrl } from 'ca-react-util-lib';

const _getData = (state: ApplicationState) => state.Site;

const getError = (state: ApplicationState) => _getData(state).error;

const isLoading = (state: ApplicationState) => _getData(state).loading;

const isPhotosLoading = (state: ApplicationState) => _getData(state).isPhotosLoading;

const getSite = (state: ApplicationState) => _getData(state).site;

const getCurrentSiteAssessmentId = (state: ApplicationState) =>
  _getData(state).currentSiteAssessmentId;

const getAssetProfilePhoto = (state: ApplicationState) =>
  _getData(state).assetProfilePhoto;

const getCurrentSiteAssessmentPhotos = (state: ApplicationState) =>
  _getData(state).currentSiteAssessmentPhotos;

const getCurrentSiteAssessmentPhotosAssetProfile = (state: ApplicationState) => {
  const photos: any = _getData(state).currentSiteAssessmentPhotos;
  return formatPhotoUrl(photos);
};

const getCurrentSiteAssessmentFolders = (state: ApplicationState) => {
  const photos = _getData(state).currentSiteAssessmentPhotos;
  let foldersTemp: any[] = Object.values(SiteImagesFolders).map((folderName) => ({
    name: folderName,
    total: _getData(state).assetProfilePhoto?.file_path_summary?.find((x: any) => x.file_path === folderName)?.images_count,
    photos: [],
  }));

  photos.forEach((photo) => {
    let index = foldersTemp.findIndex((folder) => folder.name === photo.folder);

    foldersTemp[index].photos.push({
      name: photo.pictureFile,
      url: photo.pictureLink,
      thumb: photo.thumbnail,
    });
  });

  return foldersTemp;
};

const getDailyForecasts = (state: ApplicationState) => _getData(state).dailyForecasts;

const getClientSiteIdentifier = (state: ApplicationState) => _getData(state).clientSiteIdentifier;

const getCurrentSiteAssessment = (state: ApplicationState) => {
  const siteRoot = _getData(state).site;
  const currentSiteAssessmentId = getCurrentSiteAssessmentId(state);

  if (!siteRoot || !siteRoot.site || !siteRoot?.site?.assessments || !currentSiteAssessmentId) {
    return;
  }

  return siteRoot?.site?.assessments.find(
    (siteAssessment) => siteAssessment.id === currentSiteAssessmentId
  );
};

const getIsAddSiteSuccess = (state: ApplicationState) => _getData(state).isAddSiteSuccess;

const getIsSubmitQueueForProcessingLoading = (state: ApplicationState) =>
  _getData(state).isSubmitQueueForProcessingLoading;

const getIsSubmitQueueForProcessingSuccess = (state: ApplicationState) =>
  _getData(state).isSubmitQueueForProcessingSuccess;

const getIsFetchSiteSuccess = (state: ApplicationState) => _getData(state).isFetchSiteSuccess;

//bulk insert
const getBulkInsertResult = (state: ApplicationState) => _getData(state).bulkInsertResult;

const getIsBulkInsertLoading = (state: ApplicationState) => _getData(state).isBulkInsertLoading;

//structure select options
const getStructureSelectOptions = (state: ApplicationState) =>
  _getData(state).structureSelectOptions;
const getIsStructureSelectOptionsLoading = (state: ApplicationState) =>
  _getData(state).isStructureSelectOptionsLoading;

//assessment sales order
const getIsAssessmentSalesOrder = (state: ApplicationState) =>
  _getData(state).isAssessmentSalesOrder;
const getIsAssessmentSalesOrderLoading = (state: ApplicationState) =>
  _getData(state).isAssessmentSalesOrderLoading;

const getPhotoTab = (state: ApplicationState) =>
  _getData(state).photoTab;

const getJobProgress = (state: ApplicationState) =>
  _getData(state).jobProgress;

const getPhotoLimit = (state: ApplicationState) =>
  _getData(state).photoLimit;

const getPhotoOffset = (state: ApplicationState) =>
  _getData(state).photoOffset;

const selectors = {
  getError,
  isLoading,
  isPhotosLoading,
  getSite,
  getCurrentSiteAssessment,
  getCurrentSiteAssessmentId,
  getAssetProfilePhoto,
  getCurrentSiteAssessmentPhotos,
  getCurrentSiteAssessmentFolders,
  getDailyForecasts,
  getClientSiteIdentifier,
  getCurrentSiteAssessmentPhotosAssetProfile,
  getIsAddSiteSuccess,
  getIsSubmitQueueForProcessingLoading,
  getIsSubmitQueueForProcessingSuccess,
  getIsFetchSiteSuccess,

  //structure select options,
  getStructureSelectOptions,
  getIsStructureSelectOptionsLoading,

  //assessment sales order
  getIsAssessmentSalesOrder,
  getIsAssessmentSalesOrderLoading,

  //upload job progress
  getJobProgress,

  // photos
  getPhotoTab,
  getPhotoLimit,
  getPhotoOffset,

  //bulk insert
  getBulkInsertResult,
  getIsBulkInsertLoading,
};


export default selectors;
